.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.flashcardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  height: 50vh;
  width: 50vw;
  transform: rotateY(var(--rotate-y, 0));
  transform-style: preserve-3d;
  transition: 1500ms;
  cursor: pointer;
  transform: perspective(5000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

@media (max-width: 435px) {
  .card {
    width: 85vw;
    height: 60vh;
  }
}

.cardInner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card.flipped .cardInner {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardFront {
  /* background-color: #F4F6F8; */
}

.cardBack {
  transform: rotateY(180deg);
  /* background-color: #f4f6f8; */
}

.title {
  margin-bottom: 10px;
}

.subtitle {
  margin-bottom: 10px;
}

.question {
  margin-bottom: 20px;
}

.answerTitle {
  margin-bottom: 10px;
}

.answer {
  margin-bottom: 20px;
}
