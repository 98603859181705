.flashcardQuizContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.cardQuiz {
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  height: 50vh;
  width: 50vw;
  transform: rotateY(var(--rotate-y, 0));
  transform-style: preserve-3d;
  transition: 1500ms;
  cursor: pointer;
  transform: perspective(5000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

@media (max-width: 435px) {
  .cardQuiz {
    width: 85vw;
    height: 60vh;
  }
}

.cardQuizInner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.cardQuiz.flipped .cardQuizInner {
  transform: rotateY(180deg);
}

.cardQuizFront,
.cardQuizBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardQuizFront {
  /* background-color: #F4F6F8; */
}

.cardQuizBack {
  transform: rotateY(180deg);
  /* background-color: #F4F6F8; */
}

.quizTitle {
  margin-bottom: 10px;
}

.quizSubtitle {
  margin-bottom: 10px;
}

.quizQuestion {
  margin-bottom: 20px;
}

.quizAnswerTitle {
  margin-bottom: 10px;
}

.quizAnswer {
  margin-bottom: 20px;
}

.quizButtonContainer-left {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.quizButtonContainer-right {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.answerToggleButton {
  width: 100px;
}

.answerToggleButton.Mui-selected {
  background-color: blue !important; /* Set the background color to blue for the selected button */
  color: white !important; /* Set the text color to white for better visibility */
}

.answerToggleButton.correct {
  border: 2px solid green;
}

.answerToggleButton.incorrect {
  border: 3px solid red;
}
