.flashcardLearnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}

.cardLearn {
  margin: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  height: 50vh;
  width: 50vw;
  transform: rotateY(var(--rotate-y, 0));
  transform-style: preserve-3d;
  transition: 1500ms;
  cursor: pointer;
  transform: perspective(5000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
}

@media (max-width: 480px) {
  .cardLearn {
    margin: 40px;
    width: 85vw;
    height: 80vh;
  }
}

.cardLearnInner {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.cardLearn.flipped .cardLearnInner {
  transform: rotateY(180deg);
}

.cardLearnFront,
.cardLearnBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardLearnFront {
  /* background-color: #F4F6F8; */
}

.cardLearnBack {
  transform: rotateY(180deg);
  /* background-color: #F4F6F8; */
}

.learntitle {
  margin-bottom: 10px;
}

.learnsubtitle {
  margin-bottom: 10px;
}

.learnquestion {
  margin-bottom: 20px;
}

.learnanswerTitle {
  margin-bottom: 10px;
}

.learnanswer {
  margin-bottom: 20px;
}

.learnbuttonContainer-left {
  display: flex;
  justify-content: flex-start;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.learnbuttonContainer-right {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.learnbutton {
  margin-top: 10px;
  margin-left: 10px;
}

.learnbutton-incorrect {
  background-color: #ff0000;
  color: #ffffff;
}

.learnbutton-correct {
  background-color: #00cc00;
  color: #ffffff;
}
